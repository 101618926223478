.stadium-map-container {
  width: 100%;
  max-width: 1200px; /* Adjust this value as needed */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.svg-wrapper {
  width: 100%;
  padding-bottom: 85%; /* Increased from 75% to give more vertical space */
  position: relative;
  overflow: hidden;
  margin-bottom: 20px; /* Add some margin at the bottom */
  margin-top: 5px;
}

.svg-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .stadium-map-container {
    padding: 0px;
  }
  
  .svg-wrapper {
    padding-bottom: 100%; /* Make it square on mobile for better visibility */
  }
}

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 5px 5px;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #000;
}