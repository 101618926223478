@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --primary-color: #1976d2;
  --secondary-color: #dc004e;
  --background-color: #f5f5f5;
  --text-color: #333;
  --card-background: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  background-color: var(--primary-color);
  color: white;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.app-header h2 {
  font-size: 1.5rem;
  font-weight: 300;
}

.app-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.team-selector-section,
.team-schedule-section,
.game-details-section {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .app {
    padding: 10px;
  }

  .app-header {
    padding: 30px 10px;
  }

  .app-header h1 {
    font-size: 2.5rem;
  }

  .app-header h2 {
    font-size: 1.2rem;
  }

  .team-selector-section,
  .team-schedule-section,
  .game-details-section {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .app-header h1 {
    font-size: 2rem;
  }

  .app-header h2 {
    font-size: 1rem;
  }
}